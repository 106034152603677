<template>
  <div>
    <v-list rounded class="transparent">
      <v-list-item
        v-for="ticket in tickets"
        :key="ticket.id"
        class="flex-wrap mb-2 transparent primary-hover-shadow pa-2 pa-md-0"
        style="border-bottom: 1px solid #015C89;"
      >
        <v-list-item-icon class="mr-12 pl-0 pl-md-9 my-10">
          <v-img
            v-if="event.globalEventId"
            class="primary-shadow main-border-radius align-center"
            :src="
              `https://firebasestorage.googleapis.com/v0/b/piletivahetus-develop.appspot.com/o/events%2Fjpg%2Fg_e_5h4234h${event.globalEventId}h4234h.jpg?alt=media`
            "
            :lazy-src="
              `https://firebasestorage.googleapis.com/v0/b/piletivahetus-develop.appspot.com/o/events%2Fjpg%2Fg_e_5h4234h${event.globalEventId}h4234h.jpg?alt=media`
            "
            width="125px"
            :style="$vuetify.breakpoint.smAndDown ? 'max-width: 88px' : ''"
            gradient="rgba(100,115,201,.33), rgba(25,32,72,.7)"
          >
            <v-card-title
              class="justify-center display-4 font-weight-bold white--text"
            >
              {{ ticket.subTickets ? ticket.subTickets.length || 1 : 1 }}
            </v-card-title>
          </v-img>
          <v-col
            v-if="$vuetify.breakpoint.smAndDown"
            class="flex-wrap align-center pl-4 pt-8"
          >
            <v-list-item-title
              class="primary--text headline"
              style="white-space: normal"
            >
              {{ ticket.sellerFirstName }}
            </v-list-item-title>
            <v-list-item-subtitle
              class="subtitle-1 font-weight-light text-wrap"
            >
              Müüb
              {{ ticket.subTickets ? ticket.subTickets.length || 1 : 1 }}
              piletit
            </v-list-item-subtitle>
          </v-col>
        </v-list-item-icon>
        <v-list-item-content class="pt-0 pt-md-10" style="min-width:200px">
          <v-list-item-title
            v-if="$vuetify.breakpoint.mdAndUp"
            class="primary--text headline"
            style="white-space: normal"
          >
            {{ ticket.sellerFirstName }}
          </v-list-item-title>
          <v-list-item-subtitle
            v-if="$vuetify.breakpoint.mdAndUp"
            class="subtitle-1 font-weight-light text-wrap"
          >
            Müüb
            {{ ticket.subTickets ? ticket.subTickets.length || 1 : 1 }} piletit
          </v-list-item-subtitle>
          <v-list-item-subtitle
            v-if="ticket.allowSwap"
            class="subtitle-2 font-weight-light text-wrap"
          >
            <span class="d-block mt-2 primary--text font-weight-medium">
              <v-icon color="primary">mdi-check</v-icon>
              On valmis vahetuseks
            </span>
          </v-list-item-subtitle>
          <v-list-item-subtitle
            v-if="ticket.swapDetails"
            class="text-wrap pr-3"
          >
            {{ ticket.swapDetails }}
          </v-list-item-subtitle>
          <v-list
            class="mt-3 d-flex flex-wrap pa-0"
            style="background-color:unset"
            :class="{ 'col 6': ticket.subTickets && ticket.subTickets.length }"
          >
            <div
              v-if="ticket.subTickets && ticket.subTickets.length"
              class="d-flex flex-wrap"
            >
              <ticket-subticket-item
                v-for="(sub, index) in ticket.subTickets"
                :key="index"
                :subticket="{ ...sub, index }"
              >
              </ticket-subticket-item>
            </div>
            <div v-else class="d-flex flex-wrap">
              <ticket-subticket-item :subticket="ticket">
              </ticket-subticket-item>
            </div>
          </v-list>
        </v-list-item-content>
        <v-list-item-action
          class="d-flex justify-center ma-0 flex-row flex-wrap pa-0"
          :class="{
            'py-9 w-100':
              ticket.subTickets &&
              ticket.subTickets.length &&
              $vuetify.breakpoint.mdAndUp,
            'w-100': $vuetify.breakpoint.smAndDown,
            'col col-12 col-md-12 col-lg-6':
              ticket.subTickets && !ticket.subTickets.length,
            'px-12': $vuetify.breakpoint.mdAndUp
          }"
          :style="{
            alignItems:
              ticket.subTickets &&
              !ticket.subTickets.length &&
              $vuetify.breakpoint.mdAndUp
                ? 'flex-end'
                : 'center',
            background:
              ticket.subTickets &&
              ticket.subTickets.length &&
              $vuetify.breakpoint.mdAndUp
                ? '#FFFFFF'
                : '',
            boxShadow:
              ticket.subTickets &&
              ticket.subTickets.length &&
              $vuetify.breakpoint.mdAndUp
                ? '0px 4px 20px rgba(47, 47, 47, 0.25)'
                : '',
            borderRadius: '32px',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px'
          }"
        >
          <v-col
            v-if="ticket.subTickets && ticket.subTickets.length"
            class="pa-0 d-flex justify-start"
          >
            <v-list-item-subtitle
              class="primary--text text--darken-4 font-weight-bold text-right text-md-left"
              :class="{
                'subtitle-2 mr-4': $vuetify.breakpoint.mdAndDown,
                'title ': $vuetify.breakpoint.mdAndUp
              }"
            >
              Kogu komplekt
              <span
                class="ml-10 font-weight-bold primary--text pr-3"
                :class="{
                  'headline ': $vuetify.breakpoint.mdAndDown,
                  'display-1': $vuetify.breakpoint.mdAndUp
                }"
                style="position:relative"
              >
                {{ ticket.price }}
                <span
                  class="headline mb-3"
                  :style="{
                    position: $vuetify.breakpoint.mdAndUp && 'absolute'
                  }"
                  >€</span
                ></span
              >
            </v-list-item-subtitle>
          </v-col>
          <v-col
            class="d-inline-flex align-center pa-0 flex-row justify-end flex-wrap"
            :class="{
              'w-100 flex-row': $vuetify.breakpoint.smAndDown,
              'col-sm-12': ticket.subTickets && !ticket.subTickets.length
            }"
            :style="{
              marginTop:
                $vuetify.breakpoint.lgAndUp &&
                ticket.subTickets &&
                !ticket.subTickets.length &&
                '100px !important'
            }"
          >
            <v-col
              v-if="!ticket.subTickets || (ticket.subTickets && !ticket.subTickets.length) || !tickets.length"
              cols="12"
              class="text-right"
            >
              <span
                class="ml-10 font-weight-bold primary--text"
                :class="{
                  'headline ': $vuetify.breakpoint.mdAndDown,
                  'display-1': $vuetify.breakpoint.mdAndUp
                }"
                style="position:relative"
                >{{ ticket.price
                }}<span
                  class="headline mb-3"
                  :style="{
                    position: $vuetify.breakpoint.mdAndUp && 'absolute'
                  }"
                  >€</span
                ></span
              >
            </v-col>
            <v-col
              cols="12"
              class="d-flex justify-end px-4 px-md-0 mt-8 mt-md-0"
              :class="{
                'flex-wrap flex-column-reverse': $vuetify.breakpoint.smAndDown
              }"
            >
              <v-btn
                v-if="
                  user
                    ? ticket.allowSwap && ticket.sellerId !== user.id
                    : ticket.allowSwap
                "
                color="d-block primary darken-3"
                width="250px"
                height="52px"
                class="rounded-10 mr-md-9"
                :class="{
                  'mr-0 mt-4 w-100': $vuetify.breakpoint.smAndDown
                }"
                outlined
                @click="didClickExchange(ticket)"
              >
                Paku vahetust
              </v-btn>
              <v-btn
                v-if="user ? ticket.sellerId !== user.id : true"
                color="primary darken-3"
                style="min-width:250px"
                height="52px"
                class="rounded-10"
                :class="{ 'w-100': $vuetify.breakpoint.smAndDown }"
                @click="didClickBuy(ticket)"
              >
                Osta
              </v-btn>
            </v-col>
          </v-col>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-dialog v-model="showBuyForm" max-width="290">
      <v-card>
        <v-card-title class="body-1">
          Võta ühendust
          <v-spacer></v-spacer>
          <v-btn icon small @click="showBuyForm = false">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-stepper v-model="contactTypeStepper" class="elevation-0">
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="transparent elevation-0">
                <v-form>
                  <v-btn
                    color="secondary"
                    class="mb-4 w-100"
                    :loading="emailSending"
                    @click="selectContactType('email')"
                  >
                    <v-icon class="mr-3">mdi-email</v-icon> Email
                  </v-btn>
                  <v-btn
                    color="primary"
                    class="w-100"
                    @click="selectContactType('piletivahetus')"
                  >
                    <v-icon class="mr-3">mdi-chat</v-icon> Läbi piletivahtuse
                  </v-btn>
                </v-form>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card class="transparent elevation-0">
                <v-form>
                  <v-text-field
                    id="name"
                    v-model="selectedFirstName"
                    name="name"
                    :label="$vuetify.lang.t('$vuetify.general.firstName')"
                  ></v-text-field>
                  <v-text-field
                    id="email"
                    v-model="selectedEmail"
                    name="email"
                    label="Email"
                  ></v-text-field>
                </v-form>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <v-card-actions>
          <v-btn
            v-show="contactTypeStepper > 1"
            color="secondary"
            @click="contactTypeStepper -= 1"
          >
            {{ $vuetify.lang.t('$vuetify.general.back') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-show="contactTypeStepper === 2"
            color="primary darken-3"
            @click="selectContactType('email')"
          >
            Saada meil
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showExchangeForm" max-width="290">
      <v-card>
        <v-card-title class="body-1">
          Võta ühendust
          <v-spacer></v-spacer>
          <v-btn icon small @click="showExchangeForm = false">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-stepper v-model="contactTypeStepper" class="elevation-0">
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="transparent elevation-0">
                <v-form>
                  <v-btn
                    color="secondary"
                    class="mb-4 w-100"
                    :loading="emailSending"
                    @click="selectContactType('email')"
                  >
                    <v-icon class="mr-3">mdi-email</v-icon> Email
                  </v-btn>
                  <v-btn
                    color="primary"
                    class="w-100"
                    @click="selectContactType('piletivahetus')"
                  >
                    <v-icon class="mr-3">mdi-chat</v-icon> Läbi piletivahtuse
                  </v-btn>
                </v-form>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card class="transparent elevation-0">
                <v-form>
                  <v-text-field
                    id="name"
                    v-model="selectedFirstName"
                    name="name"
                    :label="$vuetify.lang.t('$vuetify.general.firstName')"
                  ></v-text-field>
                  <v-text-field
                    id="email"
                    v-model="selectedEmail"
                    name="email"
                    label="Email"
                  ></v-text-field>
                </v-form>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <v-card-actions>
          <v-btn
            v-show="contactTypeStepper > 1"
            color="secondary"
            @click="contactTypeStepper -= 1"
          >
            {{ $vuetify.lang.t('$vuetify.general.back') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-show="contactTypeStepper === 2"
            color="primary darken-3"
            @click="selectContactType('email')"
          >
            Tee pakkumine
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <login-dialog :visible="loginDialog" @close="loginDialog = false" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import EmailsDB from '@/firebase/mails-db'
import TicketSubticketItem from '@/components/Event/TicketSubticketItem'

export default {
  name: 'TicketList',
  components: {
    TicketSubticketItem,
    LoginDialog: () =>
      import(
        /* webpackChunkName: "LoginDialog" */ '@/components/dialogs/LoginDialog'
      )
  },
  props: {
    eventId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showBuyForm: false,
      showExchangeForm: false,
      contactTypeStepper: 1,
      selectedTicket: null,
      selectedFirstName: '',
      selectedEmail: '',
      selectedContactType: '',
      selectedService: '',
      emailSending: false,
      loginDialog: false
    }
  },
  computed: {
    ...mapGetters('app', ['loading']),
    ...mapGetters('events', ['tickets', 'event']),
    ...mapGetters('authentication', ['user', 'isAuthenticated'])
  },
  created() {
    this.getAllTickets({
      id: this.eventId,
      constraints: [
        ['eventId', '==', this.eventId],
        ['status', '==', 'active']
      ],
      orderBy: ['createTimestamp', 'desc']
    })
  },
  methods: {
    ...mapActions('events', ['getAllTickets']),
    ...mapActions('chats', ['createRoom']),
    didClickBuy(item) {
      this.selectedTicket = item
      this.selectedService = 'buy'
      this.showBuyForm = true
    },
    didClickExchange(item) {
      this.selectedTicket = item
      this.selectedService = 'exchange'
      this.showExchangeForm = true
    },
    async selectContactType(type) {
      this.selectedContactType = type
      if (type === 'email') {
        if (!this.isAuthenticated && this.contactTypeStepper !== 2) {
          this.contactTypeStepper += 1
        } else {
          this.emailSending = true
          await this.sendEmail()
        }
      } else if (type === 'piletivahetus') {
        if (!this.isAuthenticated) {
          //   this.$router.push('/logi-sisse')
          this.loginDialog = true
        } else {
          await this.createRoom({
            this: this,
            item: { ticket: this.selectedTicket, event: this.event }
          })
          await this.sendEmail()
          this.showBuyForm = false
          this.showExchangeForm = false
        }
      }
    },
    async sendEmail() {
      if (
        this.selectedContactType === 'email' &&
        (this.selectedService === 'buy' || this.selectedService === 'exchange')
      ) {
        await new EmailsDB()
          .sendEmail(
            this.selectedTicket.sellerEmail,
            {
              sellerName: this.selectedTicket.sellerFirstName,
              buyerName: this.isAuthenticated
                ? this.user.firstName
                : this.selectedFirstName,
              eventName: this.selectedTicket.event.title,
              buyerEmail: this.isAuthenticated
                ? this.user.email
                : this.selectedEmail
            },
            'd-1ed090ea5b2f41e9871dd4f2f36ee596'
          )
          .then(() => {
            this.emailSending = false
            this.showSnackbar('Sinu andmed saadeti müüjale')
            this.selectedFirstName = ''
            this.selectedEmail = ''
            this.showBuyForm = false
            this.contactTypeStepper = 1
          })
      }
      if (
        this.selectedContactType === 'piletivahetus' &&
        (this.selectedService === 'buy' || this.selectedService === 'exchange')
      ) {
        new EmailsDB()
          .sendEmail(
            this.selectedTicket.sellerEmail,
            {
              sellerName: this.selectedTicket.sellerFirstName,
              buyerName: this.isAuthenticated
                ? this.user.firstName
                : this.selectedFirstName,
              eventName: this.selectedTicket.event.title
            },
            'd-1a68319b08124c9c9e0f78a3aae3d55a'
          )
          .then(() => {
            this.emailSending = false
            this.selectedFirstName = ''
            this.selectedEmail = ''
            this.showExchangeForm = false
            this.contactTypeStepper = 1
          })
      }
    },
    showSnackbar(message) {
      this.$store.commit(
        'app/setSnackbar',
        {
          show: true,
          type: 'success',
          message
        },
        { root: true }
      )
    }
  }
}
</script>
