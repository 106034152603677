<template>
  <v-list-item class="pa-0 mr-2 mb-0 col col-12 col-lg-4 col-md-3 col-sm-5">
    <span
      class="primary--text headline count mr-5 pr-2 font-weight-bold"
      style="border-right:3px solid #0184c3"
    >
      {{ subticket.index ? subticket.index + 1 : 1 }}
    </span>
    <div class="d-flex flex-wrap">
      <v-list-item-subtitle class="caption text-wrap">
        <strong>Tüüp:</strong> {{ subticket.ticketType }}
      </v-list-item-subtitle>
      <v-list-item-subtitle
        v-if="subticket.seatDetails"
        class="caption text-nowrap"
      >
        <strong>Istekoht:</strong> {{ subticket.seatDetails }}
      </v-list-item-subtitle>
    </div>
  </v-list-item>
</template>

<script>
export default {
  name: 'TicketSubticketItem',
  props: {
    subticket: {
      type: Object,
      required: true
    }
  }
}
</script>
