// import { isNil } from 'lodash'
import { firebase } from '@firebase/app'
import GenericDB from './generic-db'
import firestore from './async-firestore'

export default class MailsDB extends GenericDB {
  constructor() {
    super('mails')
  }

  /**
   * Rate user
   * @param to
   * @param subject
   * @param headers
   * @param template
   * @param dynamic_template_data
   */
  async sendEmail(to, dynamicTemplateData, templateId) {
    const collectionRef = (await firestore()).collection(this.collectionPath)
    const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp()

    const dataToCreate = {
      to,
      template: {
        name: templateId,
        data: {
          ...dynamicTemplateData
        }
      },
      // message: {
      //   template: 'd-1ed090ea5b2f41e9871dd4f2f36ee596',
      //   dynamic_template_data: dynamicTemplateData
      // },
      createTimestamp: serverTimestamp,
      updateTimestamp: serverTimestamp
    }

    const createPromise = collectionRef.add(dataToCreate).then(doc => doc.id)

    const id = await createPromise

    return {
      id,
      to,
      template: {
        name: templateId,
        data: {
          ...dynamicTemplateData
        }
      },
      createTimestamp: new Date(),
      updateTimestamp: new Date()
    }
  }
}
